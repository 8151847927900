import { createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ef8f8cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "part-details-header",
  id: "part-details-header"
}
const _hoisted_2 = { class: "left-side" }
const _hoisted_3 = { class: "part-details-header-part-number" }
const _hoisted_4 = { class: "part-details-header-part-number-full" }
const _hoisted_5 = { class: "part-details-header-revision" }
const _hoisted_6 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["NButton"], {
        class: "part-details-header-back-button",
        circle: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.router.go(-1)))
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ChevronLeft"], { class: "chevron-left" })
        ]),
        _: 1
      }),
      _createVNode($setup["NTooltip"], {
        showArrow: false,
        trigger: "hover",
        placement: "bottom-start"
      }, {
        trigger: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, _toDisplayString($setup.number), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, _toDisplayString($setup.number), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_5, _toDisplayString($setup.rev ? `REV ${$setup.rev}` : ""), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode($setup["NTabs"], { value: $setup.activeTab }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabs, (tab) => {
            return (_openBlock(), _createBlock($setup["NTabPane"], {
              name: tab.name,
              tab: tab.tab,
              key: tab.tab
            }, null, 8, ["name", "tab"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}